<template>
  <v-row no-gutters>

    <!-- Gender -->
    <v-col cols="12">
      <v-select
          outlined
          :rules="requiredRules"
        :items="$t('constants.genders.list')"
        v-model="form.gender"
        :label="$t('common.person.gender')"
      />
    </v-col>

    <!-- Firstname -->
    <v-col cols="12">
      <v-text-field
          outlined
          :rules="requiredRules"
          :label="$t('common.person.firstname')"
          maxlength="100"
          v-model="form.firstname" />
    </v-col>

    <!-- Name -->
    <v-col cols="12">
      <v-text-field
          outlined
          :rules="requiredRules"
          :label="$t('common.person.name')"
          maxlength="100"
          v-model="form.name" />
    </v-col>

    <!-- Job title -->
    <v-col cols="12">
      <v-text-field
          outlined
          :label="$t('common.person.job_title')"
          maxlength="100"
          v-model="form.position" />
    </v-col>

    <!-- Email -->
    <v-col cols="12">
      <v-text-field
          outlined
          :label="$t('common.person.email')"
          maxlength="255"
          v-model="form.email" />
    </v-col>

    <!-- Phone -->
    <v-col cols="12">
      <v-text-field
          outlined
          :label="$t('common.person.phone')"
          maxlength="100"
          v-model="form.phone" />
    </v-col>
  </v-row>
</template>

<script>
import formRules from "@/mixins/formRules";

export default {
  name: "PersonForm",

  props: {
    form: {
      type: Object,
      default: () => {
        return {
          gender: "OTHER",
          firstname: "",
          name: "",
          email: "",
          phone: "",
          position: ""
        }
      }
    }
  },

  mixins: [formRules]
}
</script>

<style scoped>

</style>
